<template>
    <v-dialog v-model="dialog" scrollable max-width="600">
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>Previous resume comments</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <div v-for="(item, index) in candidateOnlineExamNotes" class="mt-1">
                        <div><small>{{ item.created_date }}</small></div>
                        <div v-html="item.remark"></div>
                    </div>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark x-large @click="closeDialog" :loading="loading">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
    export default {
        data(){
            return{
                loading: false,
                candidateOnlineExamNotes: [],
                dialog: false,
            }
        },
        methods:{
            closeDialog(){
                this.dialog = false;
            },
            openDialog(candidateOnlineExamNotes){
                this.dialog = true;
                this.candidateOnlineExamNotes = candidateOnlineExamNotes;
            }
        }
    }
</script>