<template>
  <v-app>
    <div class="row mt-1">
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Candidate online exam result
                </h4>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="d-flex justify-space-between p-2 px-3 mb-5" style="background: #ececec; border: 0px;">
                <div>
                    <div class="font-size-lg"> <strong>Enroller</strong>: {{candidate.owner ? candidate.owner.full_name : ''}} </div>
                    <div class="font-size-lg"> <strong>Phone</strong>: {{candidate.owner ? candidate.owner.phone : '' }} {{candidate.owner ? candidate.owner.mobile : '' }} </div>
                </div>
                <div class="text-left ml-2">
                    <div class="font-size-lg"> <strong>Email</strong>: {{candidate.owner ? candidate.owner.email : ''}} </div>
                    <div class="font-size-lg"> <strong>State</strong>: {{candidate.owner ? candidate.owner.score_name : ''}} </div>
                </div>
            </div>

            <div style="overflow-x : auto;">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@5"
                >
                </v-skeleton-loader>

                <table v-if="!loading" class="table  table-vertical-center" style="min-height: 400px"
                        id="kt_advance_table_widget_4">
                    <thead>
                        <tr class="text-left">
                        <th class="">Enrolment key </th>
                        <th class="">Attempt ID</th>
                        <th class="">Type</th>
                        <th  style="max-width: 250px !important; white-space: pre-wrap;">Mark obtained</th>
                        <th  style="max-width: 270px !important; white-space: pre-wrap;" >Marked by</th>
                        <th  style="max-width: 270px !important; white-space: pre-wrap;">Start time</th>
                        <th  style="max-width: 270px !important; white-space: pre-wrap;">Completion time</th>
                        <th  style="max-width: 270px !important; white-space: pre-wrap;">Result date</th>
                        <th class="">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template>
                        <tr v-for="(item, index) in examResults" :key="index">
                            <td class="text-left">
                            <p class="font-weight-bold ">
                                {{ item.candidate_full_name ?  item.candidate_full_name : '--'}}
                            </p>
                            <p href="#" class="font-weight-bolder mb-1">
                                {{ item.candidate_exam_key ?  item.candidate_exam_key : ''}}
                            </p>

                            <div class="badge mb-2"
                                    v-bind:class="{ 'badge-success': item.is_class_exam, 'badge-danger': !item.is_class_exam }"
                            >{{ item.is_class_exam ? 'Class exam' : 'Individual' }}</div>

                            <div class="badge mt-2"
                                    v-bind:class="{ 'badge-success': item.is_marked, 'badge-danger': !item.is_marked }"
                            >{{ item.is_marked ? 'Marked' : 'Pending result' }}</div>
                            </td>
                            <td class="text-left">
                            {{ item.attempt_id }}
                            </td>
                            <td>
                                {{ item.is_official ? 'Official' : 'Practice' }}
                                <div v-if="item.candidate_online_exam_notes.length > 0">
                                    <a href="#" @click="viewOnlineExamNotes(item.candidate_online_exam_notes)">
                                        <i class="fa fa-info"></i>
                                        Previous resume comments
                                    </a>
                                </div>
                            </td>
                            <td class="text-left">
                            {{ item.mark_obtained }}
                            </td>
                            <!--                    <td class="text-left">-->
                            <!--                      {{ item.assigned_by ? item.assigned_by : '&#45;&#45;'}}-->
                            <!--                    </td>-->
                            <td class="text-left"  style="max-width: 270px !important; white-space: pre-wrap;">
                            {{ item.markedBy ? item.markedBy : '--' }}
                            </td>
                            <td class="text-left"  style="max-width: 270px !important; white-space: pre-wrap;">
                            {{ item.formatted_start_time ? item.formatted_start_time : '--' }}
                            </td>
                            <td class="text-left"  style="max-width: 270px !important; white-space: pre-wrap;">
                            {{ item.formatted_completion_time ? item.formatted_completion_time : '--' }}
                            </td>
                            <td class="text-left"  style="max-width: 270px !important; white-space: pre-wrap;">
                            {{ item.formatted_result_available_date ? item.formatted_result_available_date : '--' }}
                            </td>
                            <td class="pr-0 text-center">
                            <template>
                                <b-dropdown
                                    size="sm"
                                    variant="link"
                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                    no-caret
                                    right
                                    no-flip
                                >
                                <template v-slot:button-content>
                                    <i class="ki ki-bold-more-hor"></i>
                                </template>
                                <!--begin::Navigation-->
                                <div v-if="!item.is_official" class="navi navi-hover min-w-md-250px">
                                    <b-dropdown-text @click="deleteResult(item.id)" tag="div" class="navi-item">
                                    <a class="navi-link">
                                                        <span class="navi-icon">
                                                            <i class="fas fa-trash"></i>
                                                        </span>
                                        <span class="navi-text">
                                                            Delete
                                                        </span>
                                    </a>
                                    </b-dropdown-text>
                                </div>

                                <div v-if="item.is_official" class="navi navi-hover min-w-md-250px">
                                <b-dropdown-text tag="div" class="navi-item">
                                    <a  class="navi-link" @click="grantSecondAttemptModal(item.id)">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-exclamation-triangle</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                        Grant second official exam attempt
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text tag="div" @click="viewMarksObtained(item)" class="navi-item">
                                    <a  class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-chart-line</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                        View mark obtained
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text tag="div" @click="viewFeedbackReport(item.id)" class="navi-item">
                                    <a  class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-file</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                        View feedback report
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text tag="div" class="navi-item"  v-if="item.is_marked">
                                    <a class="navi-link" @click="getExamResultPdf(item.id)">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-file-pdf</v-icon>
                                                    </span>
                                        <span class="navi-text">View detailed report</span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text @click="printDigitalCertificate(item.id)" tag="div" class="navi-item">
                                    <a  class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-print</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                        Print digital certificate
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text @click="viewDigitalCertificate(item.id)" tag="div" class="navi-item">
                                    <a  class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-eye</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                        View digital certificates
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text @click="viewSupervisorInformation(item.candidate_id)" tag="div" class="navi-item">
                                    <a  class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-user</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                        Supervisor information
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text tag="div" class="navi-item" @click="resyncDataWithRS(item.id)">
                                    <a  class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-sync</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                        Resync data with RS
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text v-if="item.can_appeal_request" @click="requestAppeal(item.id)" tag="div" class="navi-item">
                                    <a  class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-list-alt</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                    Request appeal
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text v-if="item.has_appeal_request" @click="requestAppealProgress(item.id)" tag="div" class="navi-item">
                                    <a  class="navi-link">
                                                    <span class="navi-icon">
                                                        <v-icon color="darken-2">fas fa-list-alt</v-icon>
                                                    </span>
                                        <span class="navi-text">
                                                    Request appeal progress
                                                    </span>
                                    </a>
                                    </b-dropdown-text>

                                    <b-dropdown-text   
                                        v-if="item.is_official && item.exam_status == 'pending'"
                                        tag="div" 
                                        class="navi-item"
                                        @click="changeAccessExamStatus(item.id)"
                                    >
                                        <a  class="navi-link">
                                            <span class="navi-icon">
                                                <v-icon color="darken-2"> {{ item.can_resume ? 'fas fa-cancel' : 'fas fa-check' }} </v-icon>
                                            </span>
                                            <span class="navi-text">
                                                {{ item.can_resume ? 'Disable resume exam button' : 'Enable resume exam button' }}
                                            </span>
                                        </a>
                                    </b-dropdown-text>
                                </div>
                                </b-dropdown>
                            </template>
                            </td>
                        </tr>
                            <tr v-if="examResults.length == 0">
                                <td colspan="7" class="text-center">
                                    No results found
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>

          <!-- marks obtained modal box -->
        <v-dialog
            v-model="showMarksObtainedDialog"
            max-width="800"
            scrollable
        >
            <v-card>
                <v-toolbar dark>
                    <v-card-title>
                        <span> Marks Obtained </span>
                        <hr>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="closeDialog">
                            <i class="fa fa-close"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <div class="title">
                                    <strong>
                                        Total mark
                                    </strong>
                                </div>
                                <div class="title">
                                    {{availableMarks}}
                                </div>
                            </v-col>
                            <v-col cols="12" md="6">
                                <div class="title">
                                    <strong>
                                        Obtained
                                    </strong>
                                </div>
                                <div class="title">
                                    {{mark}}
                                </div>
                                <div class="body">
                                    {{remarks}}
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="btn btn-error"
                        dark
                        medium
                        @click="closeDialog"
                    >
                    Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- marks obtained modal box ends -->

        <!-- marks obtained modal box -->
        <v-dialog
            v-model="showSupervisorDialog"
            max-width="800"
            scrollable
        >
            <v-card>
                <v-toolbar dark>
                    <v-card-title>
                        <span> Supervisor details </span>
                        <hr>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="closeDialog">
                            <i class="fa fa-close"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-container>
                        <v-row v-if="examSupervisor">
                            <v-col cols="12" md="12">
                                <div class="subtitle-1">
                                    <strong>Name</strong>: {{examSupervisor.full_name}}
                                </div>
                            </v-col>


                            <v-col cols="12" md="12">
                                <div class="subtitle-1">
                                    <strong>Authorisation time</strong>: {{examSupervisor.verified_at? examSupervisor.verified_at: examSupervisor.created_at}}
                                </div>
                            </v-col>


                            <v-col cols="12" md="12">
                                <div class="subtitle-1">
                                    <strong>Type</strong>: {{examSupervisor.authorisation_type?examSupervisor.authorisation_type:'Mobile'}}
                                </div>
                            </v-col>


                            <v-col cols="12" md="12">
                                <div class="subtitle-1">
                                    <strong>Phone</strong>: {{examSupervisor.phone}}
                                </div>
                            </v-col>

                        </v-row>
                        <v-row v-else>
                            <div class="ml-2 title">
                                No supervisor added
                            </div>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="btn btn-error"
                        dark
                        medium
                        @click="closeDialog"
                    >
                    Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- marks obtained modal box ends -->
        <!-- Grant Second Attempt   -->
      <v-dialog
          v-model="showSecondAttemptDialog"
          max-width="800"
          scrollable
      >
        <v-card>
          <v-toolbar dark>
            <v-card-title>
              <span> Grant second official attempt </span>
              <hr>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="showSecondAttemptDialog=false">
                <i class="fa fa-close"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-col cols="12" sm="12" md="12">
                    <div class="text-body">Remarks</div>
                    <ckeditor
                        :config="editorConfig"
                        v-model="examResult.second_attempt_remarks"
                        :error="$v.examResult.second_attempt_remarks.$error"
                    >
                    </ckeditor>
                    <span class="text-danger" v-if="$v.examResult.second_attempt_remarks.$error">This information is required</span>
                  </v-col>
                </v-col>

              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                class="cancel-btn"
                x-large
                @click="showSecondAttemptDialog = false">
              Cancel
            </v-btn>
            <v-btn
                color="btn btn-primary"
                dark
                x-large
                :loading="loading"
                @click="grantSecondAttempt"
            >
              Grant attempt
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <request-appeal ref="request-appeal"  @refresh="getCandidateInfo"></request-appeal>
    <appeal-progress ref="appeal-progress" @refresh="getCandidateInfo"></appeal-progress>
    <add-online-exam-note ref="enable-exam" @refresh="getCandidateInfo"></add-online-exam-note>
    <view-online-exam-note ref="view-online-exam-notes"></view-online-exam-note>
    
  </v-app>
</template>

<script>
import CandidateService from "@/services/candidate/CandidateService";
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import CandidateOnlineExamSupervisorService from "@/services/candidate/online-exam-supervisor/CandidateOnlineExamSupervisorService";
import RequestAppeal from "@/view/pages/view/candidate/summary/partials/appeal/RequestAppeal";
import AppealProgress from "@/view/pages/view/candidate/summary/partials/appeal/AppealProgress";
import AddOnlineExamNote from "@/view/pages/view/candidate/summary/partials/exam-result/online-exam-note/AddOnlineExamNote";
import ViewOnlineExamNote from "@/view/pages/view/candidate/summary/partials/exam-result/online-exam-note/ViewOnlineExamNote";

import API_URL from "@/common/config";
import {required} from "vuelidate/lib/validators";
const candidate = new CandidateService();
const candidateOnlineExamResult = new CandidateOnlineExamResultService();
const candidateOnlineExamSupervisor = new CandidateOnlineExamSupervisorService();

export default {
  validations: {
    examResult: {
      second_attempt_remarks: {required},
    }
  },

  components:{RequestAppeal,AppealProgress,AddOnlineExamNote,ViewOnlineExamNote},
    data(){
        return {
            examKey: '',
            candidate_id: '',
            examResults: [],
            loading: true,
            showMarksObtainedDialog: false,
            showSupervisorDialog: false,
            showSecondAttemptDialog: false,
            mark: null,
            availableMarks: null,
            remarks: '',
            candidate: '',
            examSupervisor: '',
            editorConfig: {
              scayt_autoStartup: true,
              scayt_sLang: 'en_GB',
              toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
              versionCheck: false
            },
          examResult:{
             id:'',
             second_attempt_remarks:''
          }
        }
    },
    methods:{
        getExamKey(){
            this.examKey = this.$route.params.examKey;
        },
        getCandidateInfo(){
            this.loading = true;
            candidate
            .getCandidateSummary(this.examKey)
            .then((response) => {
                this.candidate = response.data.candidate;
                this.candidate_id = response.data.candidate.id;
                this.user_id=this.candidate.owner_id;
                this.getCandidateExamResults();
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        getCandidateExamResults(){
            candidateOnlineExamResult
            .getByCandidate(this.candidate_id)
            .then((response) => {
                this.examResults = response.data.examResult;
            })
            .catch((err) => {

            })
            .finally(() => {

            })
        },
        deleteResult(resultId){
            this.$confirm({
                message: `Are you sure? `,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    if (confirm) {
                        this.loading = true;
                        candidateOnlineExamResult
                        .delete(resultId)
                        .then((response) => {
                            this.$snotify.success('Result deleted');
                            this.loading = false;
                            this.getExamKey();
                            this.getCandidateInfo();
                        })
                        .catch((err) => {
                            this.$snotify.error('Oops we could not delete the result');
                            this.loading = false;
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                    }
                }
            });
        },
        changeAccessExamStatus(resultId){
            this.$refs['enable-exam'].openDialog(resultId, this.candidate_id, this.currentUser.id);
            // this.$confirm({
            //     message: `Are you sure? `,
            //     button: {
            //         no: "No",
            //         yes: "Yes",
            //     },
            //     callback: (confirm) => {
            //         if (confirm) {
            //             this.loading = true;
            //             candidateOnlineExamResult
            //             .changeCanResumeStatus(resultId)
            //             .then((response) => {
            //                 this.$snotify.success('Updated successfully');
            //                 this.loading = false;
            //                 this.getExamKey();
            //                 this.getCandidateInfo();
            //             })
            //             .catch((err) => {
            //                 this.$snotify.error('Something went wrong');
            //                 this.loading = false;
            //             })
            //             .finally(() => {
            //                 this.loading = false;
            //             })
            //         }
            //     }
            // });
        },
        viewOnlineExamNotes(candidateOnlineExamNotes){
            this.$refs['view-online-exam-notes'].openDialog(candidateOnlineExamNotes);
        },
        viewMarksObtained(result){
            this.showMarksObtainedDialog = true;
            let result_report = JSON.parse(result.result_report);
            this.mark = result_report.mark;
            this.availableMarks = result_report.availableMarks;
            this.remarks = result_report.remarks;
        },
        closeDialog(){
            this.showMarksObtainedDialog = false;
            this.showSupervisorDialog = false;
        },
        viewFeedbackReport(resultId){
            candidateOnlineExamResult
            .getExamResultPdf(resultId);
        },
        printDigitalCertificate(resultId){
            candidateOnlineExamResult
            .getDigitalCertificatePdf(resultId);
        },
        viewDigitalCertificate(resultId){
            candidateOnlineExamResult
            .viewDigitalCertificatePdf(resultId);
        },
        viewSupervisorInformation(candidateId){
            this.showSupervisorDialog = true;
            candidateOnlineExamSupervisor
            .getByOnlineExamResultId(candidateId)
            .then((response) => {
                if(response.data.status == 'OK'){
                    this.examSupervisor = response.data.candidateOnlineExamSupervisor;
                }else{
                    this.examSupervisor = '';
                }
            })
            .catch((err) => {
                this.examSupervisor = '';
            })
            .finally(() => {
            });
        },
      requestAppeal(itemId){
          this.$refs['request-appeal'].openDialog(itemId,this.user_id);
      },
      requestAppealProgress(itemId){
        this.$refs['appeal-progress'].openDialog(itemId,this.user_id);
      },
      getExamResultPdf(resultId){
        let url = API_URL+`admin/exam-result/feedback-report/${resultId}`
        return window.open(url, '_blank');
      },
      grantSecondAttemptModal(resultId)
      {
          this.examResult.id = resultId;
          this.showSecondAttemptDialog = true;
      },

      grantSecondAttempt()
      {
        this.loading = true;
        this.$v.$touch()
        if (this.$v.$error) {
          setTimeout(() => {
            this.$v.$reset()
          }, 3000);
        } else {
          candidateOnlineExamResult
              .grantSecondAttempt(this.examResult)
              .then((response) => {
                this.loading=false;
                this.showSecondAttemptDialog=false;
                this.examResult = {
                  id:'',
                  second_attempt_remarks:''

                }
                this.getCandidateExamResults();
                this.$snotify.success('Attempt granted');
              })
              .catch((err) => {
                this.loading=false;
              })
              .finally(() => {
              });
        }
      },

      resyncDataWithRS(resultId)
      {
        this.$confirm({
          message: `Are you sure you want to resync this result? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              let data = {
                result_id:resultId
              };
              candidateOnlineExamResult
                  .resyncDataWithRS(data)
                  .then((response) => {

                    this.getCandidateExamResults();
                    this.$snotify.success('Resync completed');
                  })
                  .catch((err) => {
                    this.$snotify.error('Problem occurred');
                  })
                  .finally(() => {
                  });

            }
          }
        });
      },
    },

    computed:{
        currentUser() {
            return this.$store.getters.currentUser;
        }
    },

    mounted(){
        this.getExamKey();
        this.getCandidateInfo();
    }
};
</script>