<template>
    <v-dialog v-model="dialog" scrollable max-width="600">
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>Resume exam</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row class="mt-2">
                        <v-col cols="12" md="12">
                            Remark
                            <span class="text-danger"></span>
                            <ckeditor
                                :config="editorConfig"
                                v-model="candidateOnlineExamNote.remark"
                                :error="$v.candidateOnlineExamNote.remark.$error"
                            >
                            </ckeditor>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark x-large @click="createOrUpdate()" :loading="loading">
                    Save
                </v-btn>
                <v-btn x-large text @click="dialog=false">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
    import CandidateOnlineExamNoteService from "@/services/candidate/candidate-online-exam-note/CandidateOnlineExamNoteService";
    import {required} from "vuelidate/lib/validators";
    import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";

    const candidateOnlineExamNote =  new CandidateOnlineExamNoteService();
    const candidateOnlineExamResult = new CandidateOnlineExamResultService();

    export default {
        validations:{
            candidateOnlineExamNote:{
                remark: {required}
            }
        },
        data(){
            return{
                loading: false,
                candidateOnlineExamNote: {
                    candidate_id: null,
                    admin_user_id: null,
                    exam_result_id: null,
                    remark: ''
                },
                editorConfig: {
                    toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
                    versionCheck: false
                },
                dialog: false,
            }
        },
        methods:{
            createOrUpdate(){
                this.$confirm({
                    message: `Are you sure? `,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    callback: (confirm) => {
                        candidateOnlineExamNote
                        .create(this.candidateOnlineExamNote)
                        .then((res) => {
                            this.changeAccessExamStatus();
                        })
                        .catch((err) => {
                            this.$snotify.error('Something went wrong');
                        })
                    },
                })
            },
            changeAccessExamStatus(){
                this.loading = true;
                
                candidateOnlineExamResult
                .changeCanResumeStatus(this.candidateOnlineExamNote.exam_result_id)
                .then((response) => {
                    this.$snotify.success('Updated successfully');
                    this.loading = false;
                    // this.getExamKey();
                    // this.getCandidateInfo();
                    this.closeDialog();
                })
                .catch((err) => {
                    this.$snotify.error('Something went wrong');
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                })
            },
            closeDialog(){
                this.dialog = false;
                this.$emit('refresh');
            },
            openDialog(resultId, candidateId, adminUserId){
                this.dialog = true;
                this.candidateOnlineExamNote.exam_result_id = resultId;
                this.candidateOnlineExamNote.candidate_id = candidateId;
                this.candidateOnlineExamNote.admin_user_id = adminUserId;
            }
        }
    }
</script>