<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="900" scrollable>
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            <span>Request Appeal</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div>
                   <p class="font-weight-medium title">
                  Candidates who believe they have identified an error in the way a particular examination or question has been marked may lodge an appeal. Appeals should be lodged as soon as possible after the completion of the examination. Appeals are managed through this site.

                  Fees are charged to lodge an appeal, however if the appeal is upheld the fee will be refunded. An appeal is deemed to be upheld if the overall result of the exam increases as a result of the appeal.

                  All appeal outcomes will be notified to the Enroller within 3 business days.
                </p>
                </div>
              </v-col>
              <v-col md="12">
                <v-checkbox v-model="result.agree">
                  <template v-slot:label>
                    <div> &nbsp;&nbsp; I am aware of the Terms and wish to purchase an Appeal for this Candidate’s examination.</div>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col cols="12">
                <p class="font-weight-regular subtitle-1" v-if="scoreDetail">Appeal Cost: {{scoreDetail.currency_symbol}}{{appeal_price}} + {{scoreDetail.tax_setting ? scoreDetail.tax_setting  :'GST'}}</p>
              </v-col>
              <v-col md="12">

                <v-switch
                    v-model="result.to_be_paid"
                    :label="result.to_be_paid ? 'Create appeal without payment' : 'Add to enroller\'s cart'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
             x-large text
             @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn v-if="result.agree" @click="proceedAppeal"
                x-large dark>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="appeal_overlay">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>

  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import SettingService from "@/services/setting/SettingService";
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
const score=new ScoreService();
const accountHolder=new AccountHolderService();
const setting=new SettingService();
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  data(){
    return{
      dialog:false,
      result:{
        appeal_price:'',
        agree:false,
        loading:false,
        to_be_paid:false,
      },
      appeal_price:'',
      accountHolderDetail:{},
      scoreDetail:{},
      appeal_overlay:false,
    }
  },
  methods:{
    openDialog(resultId,userId){
      this.dialog=true;
      this.result.id=resultId;
      this.getAccountHolderDetail(userId);
      this.getAppealPrice();
    },
    getAccountHolderDetail(userId) {
      accountHolder
          .show(userId)
          .then(response => {
            this.accountHolderDetail=response.data.accountHolder;
            if(this.accountHolderDetail && this.accountHolderDetail.current_score_id){
              this.getScoreDetail(this.accountHolderDetail.current_score_id)
            }
          })
          .catch((err) => {

          });
    },
    getScoreDetail(scoreId) {
      score
          .show(scoreId)
          .then(response => {
              this.scoreDetail=response.data.score;
          })

    },
    closeDialog(){
      this.dialog=false;
      this.result={
        appeal_price:'',
        agree:false,
        to_be_paid:false,
      };
      this.accountHolderDetail={};
      this.scoreDetail={};
    },
    getAppealPrice(){
      setting
          .getRequestAppealPrice()
          .then((response) => {
            this.appeal_price=response.data.appeal_price;

          })
          .catch((err) => {
            console.log(err)
          }).finally(() => {

      });
    },
    proceedAppeal(){
      this.appeal_overlay=true;
      this.result.appeal_price=this.appeal_price;
      onlineExamResult
          .createRequestAppeal(this.result.id,this.result)
          .then((response) => {
            if(response.data.extension_status.status=='ERROR'){
              this.$snotify.error(response.data.extension_status.message);
            }
            if(response.data.extension_status.status=='SUCCESS'){
              this.$snotify.success('Request Appeal Successfully');
            }
            this.$emit('refresh');
            this.closeDialog();
          })
          .catch((err) => {
            console.log(err)
          }).finally(() => {
        this.appeal_overlay=false;
      });
    }
  }
}
</script>