<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="900" scrollable>
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            <span>Request Appeal</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-stepper v-model="step">
              <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">
                  Appeal purchased
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="step > 2"
                    step="2"
                >
                  Pending decision
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  Decision made
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">

                </v-stepper-content>

                <v-stepper-content step="2">
                <div>
                  <p class="font-weight-medium title">Your Return Appeal Has Been Pending</p>

                </div>
                  <div>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio voluptatum commodi beatae est iure aspernatur, exercitationem vitae hic, reprehenderit assumenda libero quae aperiam, vero ea officia blanditiis eius necessitatibus dolores!
                    Soluta quod incidunt qui facere veritatis iure odio quia, vel enim temporibus labore, magni fuga, aut deleniti accusamus corrupti iste architecto? Quisquam tempore possimus eius, expedita dolorem fugit esse corrupti.
                    Vitae praesentium libero voluptates vero debitis sunt. Explicabo dolorum autem vero laborum nesciunt repellendus, blanditiis quas est dolorem veniam, fugit iure cupiditate delectus numqua
                  </div>



                </v-stepper-content>

                <v-stepper-content step="3">
                  <div>
                    <p class="font-weight-medium title">Your Decision for  Request Appeal is {{onlineExamResult.appeal_result_text}}.</p>
                  </div>
                  <div>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio voluptatum commodi beatae est iure aspernatur, exercitationem vitae hic, reprehenderit assumenda libero quae aperiam, vero ea officia blanditiis eius necessitatibus dolores!
                    Soluta quod incidunt qui facere veritatis iure odio quia, vel enim temporibus labore, magni fuga, aut deleniti accusamus corrupti iste architecto? Quisquam tempore possimus eius, expedita dolorem fugit esse corrupti.
                    Vitae praesentium libero voluptates vero debitis sunt. Explicabo dolorum autem vero laborum nesciunt repellendus, blanditiis quas est dolorem veniam, fugit iure cupiditate delectus numqua
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="btn btn-standard"
              dark
              medium
              @click="closeDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  data(){
    return{
      dialog:false,
      step:'1',
      onlineExamResult:{},
    }
  },
  methods:{
    closeDialog(){
      this.dialog=false;
    },
    openDialog(resultId){
      this.dialog=true;
      this.findOnlineExamResult(resultId);
    },
    findOnlineExamResult(resultId){
        onlineExamResult
            .show(resultId)
            .then((response) => {
              this.onlineExamResult=response.data.candidateOnlineExamResult;
              console.log(this.onlineExamResult)
              if(this.onlineExamResult && !this.onlineExamResult.appeal_result){
                this.step=2;
              }
              if(this.onlineExamResult && this.onlineExamResult.appeal_result){
                this.step=3;
              }

            })
            .catch((err) => {
              console.log(err)
            }).finally(() => {

        });
    },

  }
}

</script>